import { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../../context/GlobalContext";
import PaymentDetailModel from "../../models/Account/PaymentDetailModel";
import { Col, Container, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";

interface AccountInformationPageProps {
  currentKey: string | number;
  idProvider: number;
}

const AccountNotification: React.FC<AccountInformationPageProps> = ({
  currentKey,
  idProvider,
}) => {
  let { getJSON } = useContext(GlobalContext);
  const [payments, setPayments] = useState<PaymentDetailModel[]>([]);

  const getPayments = async () => {
    let params = {
      idProvider: idProvider,
    };
    await getJSON("provider", "accountPayment", params).then(
      (response: any) => {
        if (response.status) {
          setPayments(response.data);
        }
      }
    );
  };

  const MarkAsRead = (id:number ) =>{

  }

  const updatePayment = async (id: number) => {
    let params = {
      id: id,
    };
    await getJSON("provider", "updateSeenPayment", params).then(
      (response: any) => {
        if (response.status) {
        }
      }
    );
  };
  useEffect(() => {
    if (idProvider > 0) {
      getPayments();
    }
  }, [idProvider]);
  return <>
    <Container>
        <Row>
            <Col style={{fontSize: '25px', color: '#F14E95', fontWeight: '600'}}>
                Notificaciones
            </Col>
        </Row>
        <Row md={1} className="notification-container">
            {payments.map((payment: PaymentDetailModel) => {
                return(
                    <>   
                        {payment.aproved == 1 && (
                            <Row md={1} key={payment.id} className={`notification ${payment.isViewed == 1 ? 'viewed' : ''}`}>
                                <Col className="noti-title">Nueva Notificación: ¡Pago aprobado!</Col>
                                <Col>
                                    <Row md={3}>
                                        <Col className="noti-file">
                                            <a target="_blank" href={payment.file_url}>
                                                {payment.created}
                                            </a>
                                        </Col>
                                        <Col>
                                        
                                        </Col>
                                        <Col className="noti-mark">
                                            <span>
                                                Marcar como leído
                                            </span>
                                            <span>
                                                <button onClick={() => MarkAsRead(payment.id)}>
                                                    {payment.isViewed === 1 && (
                                                        <FontAwesomeIcon icon={faCircleCheck} />
                                                    )}
                                                    {payment.isViewed === 0 && (
                                                        <i className="fa-regular fa-circle-check" aria-hidden="true"></i>
                                                    )}
                                                </button>
                                            </span>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        )}    
                    </>
                )
            })}
        </Row>
    </Container>
  
  </>;
};

export default AccountNotification;
