import { Container, Row, Col, Form, Toast, Modal } from "react-bootstrap";
import AccountDetailModel from "../../models/Account/AccountDetailModel";
import { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../../context/GlobalContext";
import PaymentDetailModel from "../../models/Account/PaymentDetailModel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell } from "@fortawesome/free-solid-svg-icons";
import getNextMonthDateMexicoFormat from "../../component/getNextMonthDateMexicoFormat";

interface AccountInformationPageProps {
  currentKey: string | number;
  idProvider: number;
}

const AccountPayment: React.FC<AccountInformationPageProps> = ({
  currentKey,
  idProvider,
}) => {
  let { getJSON } = useContext(GlobalContext);
  const [payments, setPayments] = useState<PaymentDetailModel[]>([]);
  const [handleModal, setHandleModal] = useState(false);
  const [dataModal, setDataModal] = useState<{
    id: number,
    date: string;
    index: number;
    plan: string;
  }>({id: 0, date: "", index: -1, plan: "" });

  const getPayments = async () => {
    let params = {
      idProvider: idProvider,
    };
    await getJSON("provider", "accountPayment", params).then(
      (response: any) => {
        if (response.status) {
          setPayments(response.data);
        }
      }
    );
  };

  const updatePayment = async() =>{
    let params = {
      id: dataModal.id,
    };
    await getJSON("provider", "updateSeenPayment", params).then(
      (response: any) => {
        if (response.status) {

        }
      }
    );
  }

  const ShowModalNotification = (index: number) => {
    const payment = payments[index];
    setDataModal({ id: payment.id ,date: payment.created, index: index, plan: payment.plan });
    setHandleModal(true);
  };

  const displayPlan = (acrom: string) => {
    let plan = "";
    switch (acrom) {
      case "F":
        plan = "Gratuito";
        break;
      case "CB":
        plan = "Combo Básico";
        break;
      case "CM":
        plan = "Combo Medio";
        break;
      case "CS":
        plan = "Combo Silver";
        break;
      case "CS":
        plan = "Combo Oro";
        break;
      case "CP":
        plan = "Combo Platino";
        break;
      default:
        break;
    }
    return plan;
  };

  useEffect(() => {
    if (payments.length == 0) return;
    if (dataModal.id > 0 ) return;

    if(currentKey == "Pagos" && payments[0].aproved !== undefined && payments[0].aproved == 1 && payments[0].isViewed == 0){
      ShowModalNotification(0);
    }
  },[payments,currentKey]);

  useEffect(() => {
    if (idProvider > 0) {
      getPayments();
    }
  }, [idProvider]);

  useEffect(() => {
    if (!handleModal && dataModal.index >= 0) {
      const newItems = [...payments];
      newItems[dataModal.index] = { ...newItems[dataModal.index], isViewed: 1 };
      setPayments(newItems);
      updatePayment();
    }
  }, [handleModal]);


  return (
    <Container className="payment-container">
      {payments.length > 0 &&
        payments.map((payment: PaymentDetailModel, index) =>
          index === 0 ? (
            
            <>
              <Row lg={1} key={index + "payment"}>
                <Row>
                  <h5>Último Pago:</h5>
                </Row>
                <Row xs={1} md={1} className="latest-pay">
                  {payment.aproved === 1 && (
                    <span>
                      <button
                        className={`notification-pay ${
                          payments[index].isViewed ? "gray" : ""
                        }`}
                        onClick={() => {
                          ShowModalNotification(index);
                        }}
                      >
                        <FontAwesomeIcon icon={faBell} />
                      </button>
                    </span>
                  )}
                  <Col>
                    <a target="_blank" href={payment.file_url}>
                      {payment.created}
                    </a>
                  </Col>
                  <Col>
                    <span>Plan Seleccionado: {displayPlan(payment.plan)}</span>
                  </Col>
                  <Col className="status-pay">
                    <span
                      className={`payment-status ${
                        payment.aproved === 0
                          ? "process"
                          : payment.aproved === 1
                          ? "verified"
                          : "declined"
                      }`}
                    >
                      {payment.aproved === 0
                        ? "Pendiente de revisión"
                        : payment.aproved === 1
                        ? "Verificado"
                        : "Declinado"}
                    </span>
                  </Col>
                </Row>
              </Row>

              {payments.length > 1 && (
                <Row key="header">
                  <h6>Tus Pagos:</h6>
                </Row>
              )}
            </>
          ) : (
            <Row lg={1} xs={1} key={index}>
              <Row xs={1} className="other-pay">
                {payment.aproved === 1 && (
                  <span>
                    <button
                      className={`notification-pay ${
                        payments[index].isViewed ? "gray" : ""
                      }`}
                      onClick={() => {
                        ShowModalNotification(index);
                      }}
                    >
                      <FontAwesomeIcon icon={faBell} />
                    </button>
                  </span>
                )}
                <Col>
                  <a target="_blank" href={payment.file_url}>
                    {payment.created}
                  </a>
                </Col>
                <Col>
                  <span>{displayPlan(payment.plan)}</span>
                </Col>
                <Col className="status-pay">
                  <span
                    className={`payment-status ${
                      payment.aproved == 0
                        ? "process"
                        : payment.aproved === 1
                        ? "verified"
                        : "declined"
                    }`}
                  >
                    {payment.aproved == 0
                      ? "Pendiente de revisión"
                      : payment.aproved === 1
                      ? "Verificado"
                      : "Declinado"}
                  </span>
                </Col>
              </Row>
            </Row>
          )
        )}
      <Modal show={handleModal} onHide={() => setHandleModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Plan Seleccionado</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          ¡Gracias por su preferencia¡¡, a partir de este momento tu espacio de
          publicidad queda aprobado y activo hasta el día,{" "}
          <b>{getNextMonthDateMexicoFormat()} </b>
          Deseamos que este espacio sea una gran oportunidad para promover tus
          servicios y obtengas grandes beneficios.
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </Container>
  );
};

export default AccountPayment;
