import { Container, Row, Col, Form } from "react-bootstrap";
import AccountDetailModel from "../../models/Account/AccountDetailModel";
import { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../../context/GlobalContext";
import Cookies from "js-cookie";
import Select from "react-select";
import AccountDetail from "./accountDetail";

interface AccountInformationPageProps {
  currentKey: string | number;
  accountDetail: AccountDetailModel;
  editMode: boolean;
  setAccountDetail: (value: AccountDetailModel) => void;
}

const AccountAditional: React.FC<AccountInformationPageProps> = ({
  currentKey,
  accountDetail,
  editMode,
  setAccountDetail,
}) => {
  const [serviceOptions, setServiceOptions] = useState<
    { value: string; label: string }[]
  >([]);
  const [categoryOptions, setCategoryOptions] = useState<
    { value: string; label: string }[]
  >([]);
  let { getJSON, sendData } = useContext(GlobalContext);
  const [limit, setLimit] = useState<number>(0);

  const [selectedServices, setSelectedServices] = useState<{value: string,label: string}[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<{value: string,label: string}[]>([]);

  const rangeOptions = [
    {value: "Económico" , label: "Económico" },
    {value: "Accesible" , label: "Accesible" },
    {value: "Medio" , label: "Medio" },
    {value: "Alto" , label: "Alto" },

  ]

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setAccountDetail({ ...accountDetail, [name]: value });
  };

  const getOptions = () => {
    getJSON("provider", "GetServicesFront", []).then((response: any) => {
      if (response.status) {
        const dataService: { value: string; label: string }[] = [];
        response.data.forEach((service: any) => {
          dataService.push({ value: service.id, label: service.name });
        });
        setServiceOptions(dataService);
      }
    });

    getJSON("provider", "GetCategoriesFront", []).then((response: any) => {
      if (response.status) {
        const dataCategories: { value: string; label: string }[] = [];
        response.data.forEach((category: any) => {
          dataCategories.push({ value: category.id, label: category.name });
        });
        setCategoryOptions(dataCategories);
      }
    });
  };

  const getDataAccount = () => {
    let params = {
      token_web: Cookies.get("token"),
    };

    getJSON("provider", "accountDetail", params).then((response: any) => {
      if (response.status) {
        setAccountDetail(response.data);
      }
    });
  };

  useEffect(() => {
    if (
      serviceOptions.length == 0 &&
      categoryOptions.length == 0 &&
      limit < 5
    ) {
      getOptions();
    }

    if (accountDetail.id === 0 && limit < 5) {
      getDataAccount();
    }
  });

  const onChangeCategories = (e: any) => {
    const categories: number[] = [];
    e.forEach((item: { value: number; label: string }) => {
      categories.push(item.value);
    });
    setAccountDetail({
      ...accountDetail,
      categories: categories,
    });
    setSelectedCategory(e);
  };
  const onChangeServices = (e: any) => {
    const services: number[] = [];
    e.forEach((item: { value: number; label: string }) => {
      services.push(item.value);
    });
    setAccountDetail({
      ...accountDetail,
      services: services,
    });
    setSelectedServices(e);
  };

  useEffect( () => {
      if(accountDetail.services && accountDetail.services.length > 0 ) {
        const selectedOption = serviceOptions.filter(service => accountDetail.services.includes(parseInt(service.value)));
        setSelectedServices(selectedOption.length > 0 ? selectedOption : []);
      }else{
        setSelectedServices([]);
      }
  }, [serviceOptions]);

  useEffect( () => {
    if(accountDetail.categories && accountDetail.categories.length > 0 ){
      const selectedOption = categoryOptions.filter(category => accountDetail.categories.includes(parseInt(category.value)));
      setSelectedCategory(selectedOption.length > 0 ? selectedOption : []);
    }else{
      setSelectedCategory([]);
    }
  }, [categoryOptions]);

  return (
    <>
      <Row md={3} sm={1} xs={1}>
        <Col>
          <Form.Group className="mb-3" controlId="formHorizontalName">
            <Row>
              <Col md={12} style={{ textAlign: "left", fontWeight: "bold" }}>
                <Form.Label>Sitio WEB</Form.Label>
              </Col>
              <Col md={12}>
                <Form.Control
                  type="text"
                  className="shadowInput"
                  name="website"
                  onChange={handleInputChange}
                  value={accountDetail.website}
                  disabled={!editMode}
                />
              </Col>
            </Row>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="mb-3" controlId="formHorizontalName">
            <Row>
              <Col md={12} style={{ textAlign: "left", fontWeight: "bold" }}>
                <Form.Label>Instagram</Form.Label>
              </Col>
              <Col md={12}>
                <Form.Control
                  type="text"
                  className="shadowInput"
                  name="instagram"
                  onChange={handleInputChange}
                  value={accountDetail.instagram}
                  disabled={!editMode}
                />
              </Col>
            </Row>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="mb-3" controlId="formHorizontalName">
            <Row>
              <Col md={12} style={{ textAlign: "left", fontWeight: "bold" }}>
                <Form.Label>Youtube</Form.Label>
              </Col>
              <Col md={12}>
                <Form.Control
                  type="text"
                  className="shadowInput"
                  name="youtube"
                  onChange={handleInputChange}
                  value={accountDetail.youtube}
                  disabled={!editMode}
                />
              </Col>
            </Row>
          </Form.Group>
        </Col>
      </Row>
      <Row md={2}>
        <Col>
          <Form.Group className="mb-3" controlId="formHorizontalName">
            <Row>
              <Col md={12} className="label-hf" style={{ textAlign: "left" }}>
                <Form.Label>
                  Indicanos que servicios adicionales proporcionas*:
                </Form.Label>
              </Col>
              <Col md={12}>
                <Select
                  isMulti
                  name="services"
                  value={selectedServices}
                  onChange={onChangeServices}
                  options={serviceOptions}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  isDisabled={!editMode}
                />
              </Col>
            </Row>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="mb-3" controlId="formHorizontalName">
            <Row>
              <Col md={12} className="label-hf" style={{ textAlign: "left" }}>
                <Form.Label>
                  Indícanos en que secciones deseas estar en home fiesta*:
                </Form.Label>
              </Col>
              <Col md={12}>
                <Select
                  isMulti
                  name="categories"
                  value={selectedCategory}
                  onChange={onChangeCategories}
                  options={categoryOptions}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  isDisabled={!editMode}
                />
              </Col>
            </Row>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="mb-3" controlId="formHorizontalName">
            <Row>
              <Col md={12} className="label-hf" style={{ textAlign: "left" }}>
                <Form.Label>Otros servicios: </Form.Label>
              </Col>
              <Col md={12}>
                <Form.Control
                  type="text"
                  className="shadowInput"
                  value={accountDetail.other_services}
                  onChange={(event: any) => {
                    setAccountDetail({
                      ...accountDetail,
                      other_services: event.target.value,
                    });
                  }}
                  disabled={!editMode}
                />
              </Col>
            </Row>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="mb-3" controlId="formHorizontalName">
            <Row>
              <Col md={12} className="label-hf" style={{ textAlign: "left" }}>
                <Form.Label>
                  Indicanos tu rango de precio*:
                </Form.Label>
              </Col>
              <Col md={12}>
                <Select
                  name="price_range"
                  onChange={(e:any) => setAccountDetail({
                    ...accountDetail,
                    price_range: e.value
                  })}
                  value={[{value: accountDetail.price_range , label: accountDetail.price_range}]}
                  options={rangeOptions}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  isDisabled={!editMode}
                />
              </Col>
            </Row>
          </Form.Group>
        </Col>
      </Row>
      <Row md={2} xs={1}>
        <Col>
          <Form.Group className="mb-3" controlId="formHorizontalName">
            <Row>
              <Col md={12} className="label-hf" style={{ textAlign: "left" }}>
                <Form.Label>Precios Desde*:</Form.Label>
              </Col>
              <Col md={12}>
                <Form.Control
                  type="number"
                  className="shadowInput"
                  value={accountDetail.min_amount}
                  required
                  onChange={(event: any) => {
                    setAccountDetail({
                      ...accountDetail,
                      min_amount: event.target.value,
                    });
                  }}
                  disabled={!editMode}
                />
              </Col>
            </Row>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="mb-3" controlId="formHorizontalName">
            <Row>
              <Col md={12} className="label-hf" style={{ textAlign: "left" }}>
                <Form.Label>Precios Hasta*:</Form.Label>
              </Col>
              <Col md={12}>
                <Form.Control
                  type="number"
                  className="shadowInput"
                  value={accountDetail.max_amount}
                  required
                  onChange={(event: any) => {
                    setAccountDetail({
                      ...accountDetail,
                      max_amount: event.target.value,
                    });
                  }}
                  disabled={!editMode}
                />
              </Col>
            </Row>
          </Form.Group>
        </Col>
      </Row>
    </>
  );
};

export default AccountAditional;
