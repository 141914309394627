import React, { useContext, useEffect } from "react";
import ProviderWishListModel from "../../models/ProviderWishListModel";
import { GlobalContext } from "../../context/GlobalContext";
import Cookies from "js-cookie";
import { Button, Col, Container, Form, Modal, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import DataUserModel from "../../models/DataUserModel";



export const WhislistPage = () => {
    const [providers,setProviders] = React.useState<ProviderWishListModel[]>([]);
    const [totals, setTotals] = React.useState<{min: number, max:number}>({min: 0.00, max: 0.00});
    let {getJSON} =  useContext(GlobalContext);
    const [dataUser, setDataUser] = React.useState<DataUserModel>(new DataUserModel());
    const [modalShow, setModalShow] = React.useState(false);
    const [formValidatedRegister, setFormValidateRegister] = React.useState(false);



    const getProviders = async () =>{
        var providers = [];
        if(Cookies.get("whishlist") !== undefined){
            providers = JSON.parse(Cookies.get("whishlist")!! );
        }
        let params = {
            ids: providers
        }
        await  getJSON("provider", "getForWishList", params).then((response: any) => {
            if(response.status){
                setProviders(response.data.providers);
            }
        });
    }

    const deleteProvider = (id:number) =>{
        var providers = [];
        if(Cookies.get("whishlist") !== undefined){
            const currentProviders = JSON.parse(Cookies.get("whishlist")!! );
            providers = currentProviders.filter( (x:string ) => parseInt(x) !== id);
            Cookies.set('whishlist', JSON.stringify(providers));
        }

        getProviders();
    }

    const calcTotals = () =>{
        let min = 0.00;
        let max = 0.00;
        providers.forEach((provider) => {
            min = min + provider.min_amount;
            max = max + provider.max_amount;
        });

        setTotals({
            min: min,
            max: max
        });

    }

    const validateForm = async (event: any) => {
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();
    
        if(form.checkValidity()) {
            let data = {
                dataUser: dataUser,
                providers: providers
            };

            await  getJSON("provider", "sendWishList", data).then((response: any) => {
                if(response.status){
                    setProviders(response.data.providers);
                }
            });
        }
        setFormValidateRegister(true);
    }

    useEffect(() => {
        if (providers?.length === 0 || providers == undefined || providers == null){
            getProviders();
        }

       
        window.scrollTo(0, 0);
    },[]);

    useEffect(() => {
        calcTotals();
    }, [providers]);

    useEffect(() =>{
        document.title = "Home Fiesta - WishList"
    },[])

    const options = {
        style: 'decimal',  // Other options: 'currency', 'percent', etc.
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      };
      
    return(
        <Container>
             <Row className="item-provider-wish">
               <Col lg={12} className="heading-wish">
                    Lista de deseos
               </Col>
            </Row>
            {providers.length > 0 && (
                 <Row className="item-provider-wish first-table">
                    <Col lg={1} className="item-button">
                        
                    </Col>
                    <Col lg={3}>
                        
                    </Col>
                    <Col lg={4}>
                        
                    </Col>
                    <Col lg={2}>
                        <span>Minimo Estimado</span>
                    </Col>
                    <Col lg={2}>
                        <span>Maximo Estimado</span>
                    </Col>
    
                </Row>

            )}
            {providers.length > 0 && (
                providers.map( (provider: ProviderWishListModel) => {
                                
                    return(
                        <Row key={provider.id} className="item-provider-wish">
                            <Col lg={1} className="item-button">
                                <Button onClick={() => deleteProvider(provider.id)}><FontAwesomeIcon icon={faClose}/></Button>
                            </Col>
                            <Col lg={3}>
                                <img style={{borderRadius: '10px'}} src={"https://api.vivalasfiestas.com/modules/admin/proveedor/" + provider.url_image} width={170} height={'auto'} alt={provider.name} />
                            </Col>
                            <Col lg={4} style={{flexDirection: 'column'}}>
                                <Link to={"/provider/" + provider.id} className="name-wish">{provider.name}</Link>
                                <div className="description-wish" dangerouslySetInnerHTML={{__html: provider.description}}></div>
                            </Col>
                            <Col  lg={2} style={{alignItems: 'center'}}>
                                <span className="only-mobile">
                                    Mínimo Estimado:
                                </span>
                                <span style={{width: '100%', textAlign: 'right'}}>
                                     ${provider.min_amount.toLocaleString('en-US', options)}
                                </span>
                            </Col>
                            <Col lg={2} style={{alignItems: 'center'}}>
                                <span className="only-mobile">
                                    Máximo Estimado:
                                </span>
                                <span style={{width: '100%', textAlign: 'right'}}>
                                    ${provider.max_amount.toLocaleString('en-US', options)}
                                </span>
                            </Col>

                        </Row>
                    )
                })
            )}

            { providers.length == 0 && (
            
                <Row>
                    <Col className="no-results-prov">
                        Aun no has añadido proveedores a tu lista de deseos
                    </Col>
                </Row>

            )}
            {providers.length > 0 && (
                <>
                <hr className="only-mobile" />
                <Row className="item-provider-wish">
                    <Col lg={1} className="item-button">
                        
                    </Col>
                    <Col  lg={3}>
                        
                    </Col>
                    <Col lg={4}>
                        
                    </Col>
                    <Col lg={2}>
                        <span className="only-mobile">
                            Minimo Estimado:
                        </span>
                        <span style={{width: '100%', textAlign: 'right'}}>
                            <b>
                                ${totals.min.toLocaleString('en-US', options)}
                            </b>
                        </span>
                       
                    </Col>
                    <Col lg={2}>
                        <span className="only-mobile">
                            Máximo Estimado:
                        </span>
                        <span style={{width: '100%', textAlign: 'right'}}>
                            <b>
                                ${totals.max.toLocaleString('en-US', options)}
                            </b>
                        </span> 
                    </Col>
    
                </Row>

                <Row className="sendCotizacion">
                    <Col lg={{span: 4 , offset: 8}} >
                        <Button onClick={() => setModalShow(true)} >Envíar solicitud de cotización</Button>
                        
                    </Col>
                </Row>
                </>
            )}
            <Modal
            show={modalShow} onHide={() => setModalShow(false)}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                Solicitud de Cotización
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h5>Proporciona los siguientes datos:</h5>
                <Form noValidate validated={formValidatedRegister} onSubmit={validateForm}>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Correo</Form.Label>
                        <Form.Control  value={dataUser.email} 
                            onChange={ (event:any) => {
                                setDataUser({
                                    ...dataUser,
                                    email: event.target.value
                                });
                                setFormValidateRegister(false);
                            }} 
                            type="email"
                            placeholder="Tu correo..." />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicName">
                        <Form.Label>Nombre Completo</Form.Label>
                        <Form.Control 
                            value={dataUser.name} 
                            onChange={ (event:any) => {
                                setDataUser({
                                    ...dataUser,
                                    name: event.target.value
                                });
                                setFormValidateRegister(false);
                            }} 
                            type="text" 
                            placeholder="Nombre..." />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicName">
                        <Form.Label>Telefono</Form.Label>
                        <Form.Control 
                            value={dataUser.phone} 
                            onChange={ (event:any) => {
                                setDataUser({
                                    ...dataUser,
                                    phone: event.target.value
                                });
                                setFormValidateRegister(false);
                            }} 
                            type="tel" 
                            placeholder="" />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formTextArea">
                        <Form.Label>Comentarios</Form.Label>
                        <Form.Control 
                            as="textarea" 
                            rows={3}
                            value={dataUser.comments} 
                            onChange={ (event:any) => {
                                setDataUser({
                                    ...dataUser,
                                    comments: event.target.value
                                });
                                setFormValidateRegister(false);
                            }}  />
                    </Form.Group>
                    <Button variant="primary" type="submit">
                        Solicitar
                    </Button>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={() => setModalShow(false)}>Cerrar</Button>
            </Modal.Footer>
        </Modal>
        </Container>
    )
}