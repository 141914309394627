import { faPhone, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Container , Row , Col, Button, Form } from "react-bootstrap";




const ContactoPage = () => {
 
    return(
        <Container>
             <Row className="contacto">
                <Col lg={12}><p className="title">Contacto</p><p className="siguenos">Síguenos</p></Col>
                <Col lg={{span: 5, offset: 1}}>
                    <p className="title-info">Información de Contacto</p>
                    <p className="info-contact">
                        <a href="tel:5515011770">
                            <FontAwesomeIcon icon={faPhone} /> &nbsp;&nbsp;55-1501-1770
                        </a>
                        <a href="mailto:thehomefiesta@hotmail.com">
                            <FontAwesomeIcon icon={faEnvelope} />&nbsp;&nbsp;thehomefiesta@hotmail.com
                        </a>
                    </p>
                </Col>
                <Col lg={6}>
                    <p className="title-info">Escríbenos</p>
                    <Form>
                        <Row lg={2} xs={1}>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label>Nombre</Form.Label>
                                    <Form.Control required type="text" placeholder="Tu Nombre" />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label>Apellido</Form.Label>
                                    <Form.Control required type="text" placeholder="Tu Apellido" />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row lg={1} xs={1}>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control required type="email" placeholder="Tu Correo Electrónico" />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label>Asunto</Form.Label>
                                    <Form.Control required type="text" placeholder="Asunto" />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label>Mensaje</Form.Label>
                                    <Form.Control required as="textarea" rows={3} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="button-form">
                            <Button className="send-button" formAction="submit">Enviar</Button>
                        </Row>
                    </Form>
                </Col>
            </Row>
        </Container>
    )
}

export default ContactoPage;