
const Plans: {value: 'F' | 'CB' | 'CM' | 'CS' | 'CO' | 'CP', title: string, price: string, plan_specs: string}[] = [
    {
        value: 'F',
        title: 'Plan Gratuito',
        price: '0.00',
        plan_specs: "<div> Descuento 5% Sem, 10% anual <br>1 Imagen <br/>1 Espacio adicional en segmentos de area <br/></div>"
    },
    {
        value: 'CB',
        title: 'Combo Básico',
        price: '100.00',
        plan_specs: "<div> Descuento 5% Sem, 10% anual <br/>3 Imágenes <br/>Cambia 1 imagen por mes (Solo contrato anual)<br/>1 Espacio adicional en segmentos de area <br/> </div>"
    },
    {
        value: 'CM',
        title: 'Combo Medio',
        price: '200.00',
        plan_specs: "<div>Descuento 5% Sem, 10% anual <br/>3 Imágenes y 2 Videos 10seg c/u <br/>Cambia 1 imagen por mes (Solo contrato anual<br/>1 Sugerencia por mes a posible consumidor<br/>2 Espacios adicionales en segmentos de area o categorías <br/> 10 visualizaciones en slider <br/></div>"
    },
    {
        value: 'CS',
        title: 'Combo Silver',
        price: '300.00',
        plan_specs: " Descuento 5% Sem, 10% anual <br/>3 Imágenes y 2 Videos 10seg c/u <br/>Cambia 1 imagen por mes (Solo contrato anual)<br/>1 Sugerencia por mes a posible consumidor<br/>2 Espacios adicionales en segmentos de area o categorías <br/> 10 visualizaciones en slider <br/>"
    },
    {
        value: 'CO',
        title: 'Combo Oro',
        price: '500.00',
        plan_specs: "<div>Descuento 5% Sem, 10% anual <br/> 3 Imágenes y 3 videos 10 seg c/u <br/>Cambia 1 imagen y 1 video por mes  (Solo contrato anual)<br/>2 Sugerencias por mes a posible consumidor<br/>2 Espacios adicionales en segmentos de area o categorías <br/> 20 visualizaciones en slider de segmento de área<br/> 1 visualización en slider de página de inicio</div>"
    },
    {
        value: 'CP',
        title: 'Combo Platino',
        price: '1000.00',
        plan_specs: "<div>Descuento 5% Sem, 10% anual <br/>3 Imágenes y 3 videos 10 seg c/u <br/>Cambia 1 imagen y 2 video por mes  (Solo contrato anual)<br/>3 Sugerencias por mes a posible consumidor<br/>3 Espacios adicionales en segmentos de area o categorías <br/> 20 visualizaciones en slider de segmento de área<br/> 1 visualización en slider de página de inicio</div>"
    }
];


export default Plans;