import React, { Provider, useCallback, useContext, useEffect } from "react";
import { Col, Container, Row, Image, Button, Form } from "react-bootstrap"
import { Swiper, SwiperSlide } from "swiper/react"
import { Navigation } from "swiper";
import ProviderModel from "../../models/ProviderModel";
import { Link, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCoins, faFilter, faMapMarkerAlt, faStar } from "@fortawesome/free-solid-svg-icons";
import slide1 from "../../assets/providers/slide_1.webp";
import slide2 from "../../assets/providers/slide_2.webp";
import { GlobalContext } from "../../context/GlobalContext";
import MultiRangeSlider from "../../component/MultiRangeSlider/MultiRangeSlider";
import Filters from "../../models/Filters";
import CoordinateCheck from "../../component/KMRadioChecker/KmRadioCheckerComponent";
import GoogleMapReact from "google-map-react";


const AnyReactComponent = (text: any ) => <div><FontAwesomeIcon  className="marker" size='3x' icon={faMapMarkerAlt} /><p className="text-marker">{text.text}</p></div>;

export const EventsPage = () =>{
    const idEvent = useParams()["idEvent"];
    const [providers,setProviders] = React.useState<ProviderModel[]>([]);
    const [location, setLocation] = React.useState<{lat:string,lng:string}>({lat: '19.42847',lng: '-99.12766'});
    const [mapZoom, setMapZoom] = React.useState<number>(13);
    const [category,setCategory] = React.useState(''); 
    const [isEmpty,setEmpty] = React.useState(false); 
    const [showFilters, setShowFilters] = React.useState(false);
    const [filters, setFilters] = React.useState<Filters>(new Filters());
    const [services, setServices] = React.useState<{id: Number, name: string}[]>([]);
    
    const mapRef = React.createRef();

    let {getJSON} =  useContext(GlobalContext);

    const getProviders = async () =>{
        let params = {
            "idEvent": idEvent
        }
        await  getJSON("provider", "getAllWithImagesByEvent", params).then((response: any) => {
            if(response.status){
                setEmpty(response.data.providers.length > 0 ?  false : true);
                setProviders(response.data.providers);
                setCategory(response.data.category);
            }
        });
    }

    const getServices = async () => {
        let params = {

        }
        
        await  getJSON("category", "getAllServices", params).then((response: any) => {
            if(response.status){
               setServices(response.data);
            }
        });

    }

    const handlePopUpFilter = () => {
        setFilters({
            ...filters
        })
        setShowFilters(!showFilters);
    }

    const ChangeSlide = (min:number , max: number) =>{
       
        setFilters({
            ...filters,
            max_amount: max,
            min_amount: min
        });
        console.log(`Valores recibidos: valMin = ${min}, valMax = ${max}`);
    }

    const changeCheckBoxFilter = (e:any) => {

        if (Array.isArray(filters.services) && filters.services.includes(e.target.value)) {
            setFilters({
                ...filters,
                services: filters.services.filter( (service: any) => service !== e.target.value)
            });
        } else {
            setFilters({
                ...filters,
                services: [...filters.services, e.target.value]
            });

        }
    }

    const changeRadioFilter = (e:any) => {
        setFilters({
            ...filters,
            quality: parseInt(e.target.value)
        });

        
    }

    const changeInputNumber = (e:any) => {
        if(e.target.name === 'from'){
            setFilters({
                ...filters,
                min_amount: parseFloat(e.target.value)!! ? parseFloat(e.target.value) : undefined
            });
        }else if(e.target.name === 'to'){
            setFilters({
                ...filters,
                max_amount: parseFloat(e.target.value)!! ? parseFloat(e.target.value) : undefined
            });
        }
    }

    const applyFilters = async (e:any) => {
        e.preventDefault();
        if(filters.services.length === 0){
            setFilters({
                ...filters,
                services: 0
            })
        }
        let params = filters;
        await  getJSON("provider", "getAllWithFiltersByEvent", params).then((response: any) => {
            if(response.status){
                setProviders(response.data.providers);
                handlePopUpFilter();
            }
        });
    }
    const filterData = () => {

    }

    const successLocation = (position:any) => {
        const latitude = position.coords.latitude;
        const longitude = position.coords.longitude;
        setLocation({ lat: latitude, lng: longitude });
    }
    
    const errorLocation = () => {
        console.log("Unable to retrieve your location");
    }
    
    const getLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(successLocation, errorLocation);
        } else {
            console.log("Geolocation not supported");
        }
    }

    const changeSelectAddress = (e:any) => {
        const value = e.target.value;
        e.preventDefault();

        switch (value) {
            case 'azcapo':
                setLocation({lat: '19.48698',lng: '-99.18594'})
                break;
            case 'iztapa':
                setLocation({lat: '19.35529',lng: '-99.06224'})
                break;
            case 'chimal':
                setLocation({lat: '19.42155',lng: '-98.95038'})
                break;
            case 'tlalpan':
                setLocation({lat: '19.29513',lng: '-99.16206'})
                break;
            case 'bj':
                setLocation({lat: '19.3984',lng: '-99.15766'})
                break;
            case 'my':
                getLocation();
                break;
            default:
                break;
        }

        setMapZoom(12);


        if(mapRef !== null && mapRef.current !== undefined && mapRef.current !== null ){                
            //@ts-ignore
            mapRef.current!.fitToElements({
                edgePadding: {
                    bottom: 50, right: 50, left: 50, top: 50
                }, 
                animated: true 
            });
        }
    }

    
    useEffect(() => {
        getProviders();
        getServices();
        setFilters({
            ...filters,
            idEvent: parseInt(idEvent!!)
        })
    },[idEvent]);

    /**useEffect(() => {
        console.log(filters);
    }, [filters]);**/

    useEffect(() => {
        window.scrollTo(0, 0);
    },[idEvent]);

    useEffect(() =>{
        document.title = "Home Fiesta - Eventos"
    },[])
    return(
        <Container >
            <Row>
                <Col lg={12}>
                    <Swiper
                        spaceBetween={50}
                        slidesPerView={1}
                        navigation={true}
                        className="swiper-provider"
                        modules={[Navigation]}
                        >
                        {providers.map((provider:ProviderModel , index: number) => {
                            {if(provider.in_provider_section){
                                return <SwiperSlide key={index} className="swiper-content-provider">
                                    <Image src={provider.url} alt={provider.name}/>
                                </SwiperSlide>
                            }}
                        })}
                            
                            
                    </Swiper>
                </Col>
            </Row>
            <Row className="heading-row">
                <Col lg={12}>
                    <span className="heading-providers">Explora profesionales de {category}</span>
                </Col>
                <div className="filterButton">
                    <span onClick={handlePopUpFilter}><FontAwesomeIcon icon={faFilter} /> Filtrar </span>
                </div>
            </Row>
            <Row lg={4} xs={2} className="providers-grid">
                
                {providers.map((provider:ProviderModel, index:number) => {
                    const url = provider.url === null ? 'https://api.vivalasfiestas.com/modules/admin/categoria/default_category.png' : provider.url;
                            
                    return <Col key={index} className="swiper-content-providers">
                    <Link to={"/provider/" + provider.id}>
                        <Image src={url} alt={provider.name} />
                        <span className="title">{provider.name}</span>
                        {provider.servicios !== undefined &&
                            <span className="servicios">{ provider.servicios}</span>
                        }
                        {provider.servicios === undefined &&
                            <div style={{fontSize: '14px',color: '#545454', minHeight: '64px', maxHeight: '64px'}} dangerouslySetInnerHTML={{__html: provider.description}}>

                            </div>

                        }
                        <span className="from"> <FontAwesomeIcon icon={faCoins} /> &nbsp; Desde: ${provider.min_amount !== undefined ? provider.min_amount : 0.00}</span>
                        <Link to={"/provider/" + provider.id} className="view-more">Ver Más</Link>
                    </Link>
                </Col>
                })}

                
                
            </Row>
            <Row className="ubica">
                <Col lg={12}>
                    <p className="text-ubica">Ubica Proveedores en tu Zona</p>
                    <select onChange={changeSelectAddress} name="delegacion" id="delegacion-select">
                        <option value="">--Selecciona--</option>
                        <option value="azcapo">(Ciudad de México) Azcapotzalco</option>
                        <option value="iztapa">(Ciudad de México) Iztapalapa</option>
                        <option value="chimal">(Estado de México) Chimalhuacán</option>
                        <option value="tlalpan">(Ciudad de México) Tlalpan</option>
                        <option value="bj">(Ciudad de México) Benito Juárez</option>
                        <option value="my">Utiliza mi ubicación </option>
                    </select>
                </Col>
                <Col lg={12}>
                    <div style={{position: "relative"}}>
                        <GoogleMapReact
                            bootstrapURLKeys={{ key: "AIzaSyBJywWDNp2KQiWH6yoUbDR4VX0OFHk_24I"}}
                            center={{lat: parseFloat(location.lat), lng: parseFloat(location.lng) }}
                            zoom={mapZoom}
                            //@ts-ignore
                            ref={mapRef}
                            yesIWantToUseGoogleMapApiInternals
                            style= {{height: "450px", width: "100%", display: "block"}}
                            draggable={true}
                        >
                            {providers.map((provider: any, index) => {
                                const coordinatesProv = provider.coordinates.split(',');
                                const coordinatesReferer: {lat: string , lng: string} = {lat: coordinatesProv[0], lng: coordinatesProv[1]}
                                    const name = provider.name;
                                    if(CoordinateCheck(coordinatesReferer, location , 10)){
                                        return <AnyReactComponent
                                            key={index}
                                            lat={parseFloat(coordinatesProv[0])}
                                            lng={parseFloat(coordinatesProv[1])}
                                            className="marker"
                                            draggable={true}
                                            text={name}
                                        />
                                    }else{
                                        return <></>
                                    }
                                    
                                
                            })} 

                            <AnyReactComponent
                                lat={parseFloat(location.lat)}
                                lng={parseFloat(location.lng)}
                                className="marker"
                                draggable={true}
                                text={'Tu estás aquí'}
                            />

                        </GoogleMapReact>
                    </div>
                </Col>
            </Row>
            {isEmpty &&(
                    <span className="emptyProviders">
                        ¡Próximamente encontrarás proveedores para ésta categoría!
                    </span>
            )}
            {showFilters && (
                <div className="popup-overlay">
                    <div className="popup">
                        <button className="close-button" onClick={handlePopUpFilter}>
                        Cerrar
                        </button>
                        <Container className="filters-rows">
                            <Form noValidate onSubmit={filterData}>
                                <Row>
                                    <Col lg={12} className="filterTitle" >
                                        Por rango de precio:
                                    </Col>
                                    <Col lg={12} className="inputCol">
                                        <div className="inputGroupCol">
                                            <label>Desde $ :</label>
                                            <input onChange={changeInputNumber} step={1} min={0.00} name="from" type="number" value={filters.min_amount} />
                                        </div>
                                        <div className="inputGroupCol">
                                            <label>Hasta $ :</label>
                                            <input onChange={changeInputNumber} step={1} min={0.00} name="to" type="number" value={filters.max_amount} />
                                        </div>
                                    </Col> 
                                    {/**<MultiRangeSlider
                                        min={10}
                                        max={5000}
                                        onChange={({ min, max }) => ChangeSlide(min,max)}
                                    />**/}
                                </Row>
                                <Row>
                                    <Col lg={12} className="filterTitle" >
                                    Por servicios:
                                    </Col>
                                    <Col lg={12} className="scrollable-input">
                                        {services.map((service: any , index: number) => {
                                            
                                            return <div key={index} className="inputGroup">
                                                <input  onChange={changeCheckBoxFilter} defaultChecked={Array.isArray(filters.services) && filters.services.includes(service.id.toString())} checked={Array.isArray(filters.services) && filters.services.includes(service.id.toString())} name="cb-service" type="checkbox" value={service.id} />
                                                <label>{service.name}</label> 
                                            </div>
                                        })}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={12} className="filterTitle">
                                    Por calificación:
                                    </Col>
                                    <Col lg={12} className="scrollable-input">
                                        <div className="inputGroup">
                                            <input onChange={changeRadioFilter} checked={filters.quality === 1 ? true : false} name="cb-quality" type="radio" value='1' />
                                            <label> 1 <FontAwesomeIcon icon={faStar} /></label> 
                                        </div>
                                        <div className="inputGroup">
                                            <input onChange={changeRadioFilter} checked={filters.quality === 2 ? true : false} name="cb-quality" type="radio" value='2' />
                                            <label> 2 <FontAwesomeIcon icon={faStar} /></label> 
                                        </div>
                                        <div className="inputGroup">
                                            <input onChange={changeRadioFilter} checked={filters.quality === 3 ? true : false} name="cb-quality" type="radio" value='3' />
                                            <label> 3 <FontAwesomeIcon icon={faStar} /></label> 
                                        </div>
                                        <div className="inputGroup">
                                            <input onChange={changeRadioFilter} checked={filters.quality === 4 ? true : false} name="cb-quality" type="radio" value='4' />
                                            <label> 4<FontAwesomeIcon icon={faStar} /></label> 
                                        </div>
                                        <div className="inputGroup">
                                            <input onChange={changeRadioFilter} checked={filters.quality === 5 ? true : false} name="cb-quality" type="radio" value='5' />
                                            <label> 5 <FontAwesomeIcon icon={faStar} /></label> 
                                        </div>
                                    </Col>
                                </Row>
                                <Button onClick={applyFilters} className="sendFilters">Aplicar Filtros</Button> 
                            </Form>
                        </Container>
                    </div>
                </div>
            )}
        </Container>
    )
}