import { useContext, useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  Row,
  Spinner,
  Tab,
  Tabs,
} from "react-bootstrap";
import AccountDetailModel from "../../models/Account/AccountDetailModel";
import Cookies from "js-cookie";
import { GlobalContext } from "../../context/GlobalContext";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import Plans from "../../models/Plan/Plan";

const AccountInformationPage = () => {
  const [key, setKey] = useState<string | null>("1");
  const navigate = useNavigate();
  let { getJSON, sendData } = useContext(GlobalContext);
  const [formValidateAccount, setFormValidateAccount] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [accountDetail, setAccountDetail] = useState<AccountDetailModel>(
    new AccountDetailModel()
  );
  const [error, setError] = useState("");
  const [file, setFile] = useState<File | undefined>();
  const [uploaded, setUploaded] = useState(false);
  const [pricePlan, setPricePlan] = useState<string>("0.00");

  const today = new Date();
  const month = today.getMonth() + 1;
  const year = today.getFullYear();
  const date = today.getDate();
  const currentDate = month.toString() + date.toString() + year.toString();
  const [serviceOptions, setServiceOptions] = useState<
    { value: string; label: string }[]
  >([]);
  const [categoryOptions, setCategoryOptions] = useState<
    { value: string; label: string }[]
  >([]);
  const [limit, setLimit] = useState<number>(0);

  const planData = Plans;

  const getOptions = () => {
    getJSON("provider", "GetServicesFront", []).then((response: any) => {
      if (response.status) {
        const dataService: { value: string; label: string }[] = [];
        response.data.forEach((service: any) => {
          dataService.push({ value: service.id, label: service.name });
        });
        setServiceOptions(dataService);
      }
    });

    getJSON("provider", "GetCategoriesFront", []).then((response: any) => {
      if (response.status) {
        const dataCategories: { value: string; label: string }[] = [];
        response.data.forEach((category: any) => {
          dataCategories.push({ value: category.id, label: category.name });
        });
        setCategoryOptions(dataCategories);
      }
    });
  };

  const onChangeCategories = (e: any) => {
    const categories: number[] = [];
    e.forEach((item: { value: number; label: string }) => {
      categories.push(item.value);
    });
    setAccountDetail({
      ...accountDetail,
      categories: categories,
    });
  };
  const onChangeServices = (e: any) => {
    const services: number[] = [];
    e.forEach((item: { value: number; label: string }) => {
      services.push(item.value);
    });
    setAccountDetail({
      ...accountDetail,
      services: services,
    });
  };

  const validateForm = (event: any) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();

    if (form.checkValidity()) {
      sendAccountData();
    }

    setFormValidateAccount(true);
  };

  const getDataAccount = () => {
    let params = {
      token_web: Cookies.get("token"),
    };

    getJSON("provider", "accountDetail", params).then((response: any) => {
      if (response.status) {
        setAccountDetail(response.data);
      }
    });
  };

  const sendAccountData = () => {
    if (
      key === "2" &&
      (!accountDetail.acceptTerms || accountDetail.acceptTerms == undefined)
    ) {
      alert("Debes aceptar los términos y condiciones para continuar");
    } else {
      if (key == "2" && accountDetail.selectedPlan == "F") {
        navigate("/account?noti=free");
      }

      setLoading(true);
      let data = {
        token_web: Cookies.get("token"),
        detail: accountDetail,
      };
      getJSON("provider", "updateData", data).then((response: any) => {
        if (response.status) {
          let current = parseInt(key!!);
          let newvalue = current + 1;
          setKey(newvalue.toString());
        } else {
          navigate("/login");
        }
      });
      setLoading(false);
    }
  };

  const toAccount = () => {
    navigate("/account?noti=pending");
  };

  const handleOnChangeFile = (e: React.FormEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement & {
      files: FileList;
    };

    setFile(target.files[0]);
  };

  useEffect(() => {
    document.title = "Home Fiesta - Completa tu información";
  });

  const submitFile = () => {
    if (typeof file === "undefined") return;

    const formData: FormData = new FormData(undefined);
    const token = Cookies.get("token");
    const idProvider = accountDetail.id.toString();

    formData.append("file", file);
    formData.append("idProvider", idProvider);
    formData.append("token_web", token!!.toString());
    formData.append("plan", accountDetail.selectedPlan);

    sendData("provider", "uploadFile", formData, undefined, "formData").then(
      (response: any) => {
        if (response.status) {
          console.log(response);
          setUploaded(true);
        } else {
          console.log(response);
        }
      }
    );
  };

  useEffect(() => {
    if (
      serviceOptions.length == 0 &&
      categoryOptions.length == 0 &&
      limit < 5
    ) {
      getOptions();
    }

    if (accountDetail.id === 0 && limit < 5) {
      getDataAccount();
    }
  });

  return (
    <Container>
      <Tabs
        defaultActiveKey="profile"
        id="fill-tab-example"
        className="mb-3"
        activeKey={key!!}
        onSelect={(k) => setKey(k)}
        fill
      >
        <Tab eventKey="1" title="Información">
          <Row>
            <Col>
              <span
                style={{
                  display: "flex",
                  justifyContent: "center",
                  fontSize: "17px",
                  paddingBottom: "25px",
                  color: " #F14E95",
                  fontWeight: "600",
                }}
              >
                Por motivos de seguridad y para proporcionar el adecuado
                funcionamiento de los servicios del portal, es necesario
                proporcione usted la siguiente información.
              </span>
            </Col>
          </Row>
          <Form
            noValidate
            validated={formValidateAccount}
            onSubmit={validateForm}
          >
            <Row md={1} xs={1} lg={1}>
              <Col>
                <Form.Group className="mb-3" controlId="formHorizontalName">
                  <Row>
                    <Col
                      md={3}
                      className="label-hf"
                      style={{ textAlign: "right" }}
                    >
                      <Form.Label>
                        Nombre Completo Representante Legal
                      </Form.Label>
                    </Col>
                    <Col md={9}>
                      <Form.Control
                        type="text"
                        className="shadowInput"
                        value={accountDetail.name}
                        disabled
                      />
                    </Col>
                  </Row>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="formHorizontalName">
                  <Row>
                    <Col
                      md={3}
                      className="label-hf"
                      style={{ textAlign: "right" }}
                    >
                      <Form.Label>Nombre Completo del Contacto*</Form.Label>
                    </Col>
                    <Col md={9}>
                      <Form.Control
                        type="text"
                        className="shadowInput"
                        placeholder="Nombre..."
                        value={accountDetail.contact_name}
                        onChange={(event: any) => {
                          setAccountDetail({
                            ...accountDetail,
                            contact_name: event.target.value,
                          });
                          setFormValidateAccount(false);
                        }}
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        Por favor, ingresa el nombre del contacto.
                      </Form.Control.Feedback>
                    </Col>
                  </Row>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="formHorizontalName">
                  <Row>
                    <Col
                      md={3}
                      className="label-hf"
                      style={{ textAlign: "right" }}
                    >
                      <Form.Label>Email del Contacto*</Form.Label>
                    </Col>
                    <Col md={9}>
                      <Form.Control
                        type="text"
                        className="shadowInput"
                        value={accountDetail.username}
                        disabled
                      />
                    </Col>
                  </Row>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="formHorizontalName">
                  <Row>
                    <Col
                      md={3}
                      className="label-hf"
                      style={{ textAlign: "right" }}
                    >
                      <Form.Label>RFC*</Form.Label>
                    </Col>
                    <Col md={9}>
                      <Form.Control
                        type="text"
                        className="shadowInput"
                        maxLength={13}
                        placeholder="RFC..."
                        value={accountDetail.rfc}
                        onChange={(event: any) => {
                          setAccountDetail({
                            ...accountDetail,
                            rfc: event.target.value,
                          });
                          setFormValidateAccount(false);
                        }}
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        Por favor, ingresa tu RFC.
                      </Form.Control.Feedback>
                    </Col>
                  </Row>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="formHorizontalName">
                  <Row>
                    <Col
                      md={3}
                      className="label-hf"
                      style={{ textAlign: "right" }}
                    >
                      <Form.Label>CURP*</Form.Label>
                    </Col>
                    <Col md={9}>
                      <Form.Control
                        type="text"
                        className="shadowInput"
                        maxLength={18}
                        placeholder="CURP..."
                        value={accountDetail.curp}
                        onChange={(event: any) => {
                          setAccountDetail({
                            ...accountDetail,
                            curp: event.target.value,
                          });
                          setFormValidateAccount(false);
                        }}
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        Por favor, ingresa tu CURP.
                      </Form.Control.Feedback>
                    </Col>
                  </Row>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="formHorizontalName">
                  <Row>
                    <Col
                      md={3}
                      className="label-hf"
                      style={{ textAlign: "right" }}
                    >
                      <Form.Label>Nombre del segundo contacto*</Form.Label>
                    </Col>
                    <Col md={9}>
                      <Form.Control
                        type="text"
                        className="shadowInput"
                        value={accountDetail.second_contact_name}
                        onChange={(event: any) => {
                          setAccountDetail({
                            ...accountDetail,
                            second_contact_name: event.target.value,
                          });
                          setFormValidateAccount(false);
                        }}
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        Por favor, ingresa el nombre del contacto.
                      </Form.Control.Feedback>
                    </Col>
                  </Row>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="formHorizontalName">
                  <Row>
                    <Col
                      md={3}
                      className="label-hf"
                      style={{ textAlign: "right" }}
                    >
                      <Form.Label>Email del segundo contacto*</Form.Label>
                    </Col>
                    <Col md={9}>
                      <Form.Control
                        type="email"
                        className="shadowInput"
                        value={accountDetail.second_contact_email}
                        onChange={(event: any) => {
                          setAccountDetail({
                            ...accountDetail,
                            second_contact_email: event.target.value,
                          });
                          setFormValidateAccount(false);
                        }}
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        Por favor, ingresa el email del contacto.
                      </Form.Control.Feedback>
                    </Col>
                  </Row>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="formHorizontalName">
                  <Row>
                    <Col
                      md={3}
                      className="label-hf"
                      style={{ textAlign: "right" }}
                    >
                      <Form.Label>Nombre del tercer contacto</Form.Label>
                    </Col>
                    <Col md={9}>
                      <Form.Control
                        type="text"
                        className="shadowInput"
                        value={accountDetail.third_contact_name}
                        onChange={(event: any) => {
                          setAccountDetail({
                            ...accountDetail,
                            third_contact_name: event.target.value,
                          });
                          setFormValidateAccount(false);
                        }}
                      />
                    </Col>
                  </Row>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="formHorizontalName">
                  <Row>
                    <Col
                      md={3}
                      className="label-hf"
                      style={{ textAlign: "right" }}
                    >
                      <Form.Label>Email del tercer contacto</Form.Label>
                    </Col>
                    <Col md={9}>
                      <Form.Control
                        type="email"
                        className="shadowInput"
                        value={accountDetail.third_contact_email}
                        onChange={(event: any) => {
                          setAccountDetail({
                            ...accountDetail,
                            third_contact_email: event.target.value,
                          });
                          setFormValidateAccount(false);
                        }}
                      />
                    </Col>
                  </Row>
                </Form.Group>
              </Col>

              <div
                style={{
                  color: "red",
                  textAlign: "center",
                  fontSize: "14px",
                  display: error !== "" ? "block" : "none",
                }}
              >
                {error}
              </div>
            </Row>
            <Row>
              <Col md={12}>
                <p style={{ textAlign: "right" }}> *Datos obligatorios </p>
              </Col>
            </Row>
            <Row>
              <Col
                md={{ offset: 9, span: 3 }}
                style={{
                  textAlign: "center",
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <Button
                  disabled={isLoading}
                  style={{ backgroundColor: "rgb(241, 46, 149)" }}
                  type="submit"
                >
                  <Spinner
                    className={
                      isLoading ? "spinner-active" : "spinner-not-active"
                    }
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                  {isLoading ? "Enviando..." : "Enviar"}
                </Button>
              </Col>
            </Row>
          </Form>
        </Tab>
        <Tab
          disabled={parseInt(key!!) <= 2}
          eventKey="2"
          title="Selecciona tu plan"
        >
          <Row md={3} lg={6} xs={2}>
            {planData.map((plan: any, index: number) => {
              return (
                <Col
                  key={index + "card-plan"}
                  className={`card-plan ${
                    plan.value == accountDetail.selectedPlan ? "selected" : ""
                  }`}
                >
                  <span className="plan-title">
                    {" "}
                    {plan.title + `(${plan.value})`}
                  </span>
                  <span className="plan-price"> $ {plan.price} MXN / Mes </span>
                  <div
                    className="plan-especs"
                    dangerouslySetInnerHTML={{ __html: plan.plan_specs }}
                  ></div>
                  <span>
                    <input
                      type="radio"
                      name="plan"
                      value={plan.value}
                      onChange={(e: any) => {
                        setAccountDetail({
                          ...accountDetail,
                          selectedPlan: e.target.value,
                        });
                        setPricePlan(plan.price);
                      }}
                    />
                  </span>
                </Col>
              );
            })}
          </Row>
          <Row>
            <Form.Group>
              <Row>
                <Col className="terms_conditions">
                  <Form.Check
                    required
                    name="terms"
                    label="Acepta los"
                    checked={accountDetail.acceptTerms}
                    onChange={(event: any) => {
                      setAccountDetail({
                        ...accountDetail,
                        acceptTerms: event.target.checked,
                      });
                    }}
                    id="validationFormik0"
                  />
                  <a
                    href="/terms_conditions"
                    className="pink-text"
                    target="_blank"
                  >
                    Terminos y Condiciones
                  </a>
                </Col>
              </Row>
            </Form.Group>
          </Row>
          <Row>
            <div className={`progressSteps`}>
              <Button
                onClick={() => {
                  setKey("1");
                }}
              >
                Anterior
              </Button>
              <Button onClick={() => sendAccountData()}>Siguiente</Button>
            </div>
          </Row>
        </Tab>
        <Tab
          disabled={parseInt(key!!) <= 3}
          eventKey="3"
          title="Datos Bancarios"
        >
          <div
            style={{
              fontFamily: "Arial, sans-serif",
              padding: "20px",
              maxWidth: "600px",
              margin: "auto",
              border: "1px solid #ddd",
              borderRadius: "10px",
              boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
            }}
          >
            <h2 style={{ textAlign: "center" }}>
              Método de Pago para Suscripción
            </h2>
            <p style={{ textAlign: "center" }}>
              Por favor, realiza la transferencia interbancaria a la siguiente
              cuenta:
            </p>
            <div style={{ textAlign: "center", margin: "20px 0" }}>
              <img
                src="https://via.placeholder.com/50"
                alt="Transferencia Bancaria"
                style={{ verticalAlign: "middle", marginBottom: "10px" }}
              />
              <h3>Datos de la Cuenta</h3>
              <p>
                <strong>Banco:</strong> Banco Ejemplo
              </p>
              <p>
                <strong>CLABE:</strong> 123456789012345678
              </p>
              <p>
                <strong>Número de Cuenta:</strong> 1234567890
              </p>
              <p>
                <strong>Referencia / Concepto:</strong>
                {accountDetail.id + "_" + currentDate + "_homefiesta"}
              </p>
              <p style={{ fontSize: "1.3em" }}>
                <strong>Total a pagar:</strong> ${pricePlan} MXN
              </p>
            </div>
            <p
              style={{
                textAlign: "center",
                color: "#F14E95",
                fontWeight: "600",
              }}
            >
              En caso de requerir facturación por favor enviar su constancia de
              situación fiscal debe corresponder al nombre de quien es el
              proveedor registrado al siguiente correo electrónico
            </p>
            <p style={{ textAlign: "center" }}>
              Para más información o asistencia, contáctanos en{" "}
              <a href="mailto:support@example.com">
                contacto@vivalasfiestas.com
              </a>
              .
            </p>
          </div>
          <Row>
            <div className={`progressSteps`}>
              <Button
                onClick={() => {
                  setKey("2");
                }}
              >
                Anterior
              </Button>
              <Button
                onClick={() => {
                  setKey("4");
                }}
              >
                Siguiente
              </Button>
            </div>
          </Row>
        </Tab>
        <Tab disabled={key !== "4"} eventKey="4" title="Sube tu comprobante">
          <Row md={1} className="uploadFile">
            <Col>
              <span>Sube tu comprobante a continuación </span>
            </Col>
            <Form>
              <input
                id="image"
                type="file"
                name="image"
                accept="image/png, image/jpg, application/pdf"
                onChange={handleOnChangeFile}
              />
              <Button
                onClick={submitFile}
                disabled={isLoading}
                style={{
                  backgroundColor: "rgb(241, 46, 149)",
                  maxWidth: "200px",
                }}
              >
                <Spinner
                  className={
                    isLoading ? "spinner-active" : "spinner-not-active"
                  }
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
                {isLoading ? "Enviando..." : "Enviar"}
              </Button>
            </Form>
            <div
              style={{
                color: "green",
                textAlign: "center",
                fontSize: "14px",
                display: uploaded ? "block" : "none",
              }}
            >
              {" "}
              Se ha subido tu documento correctamente, ahora esta en proceso de
              revisión y te notificaremos cuando haya sido revisado
            </div>
          </Row>
          <Row style={{ paddingTop: "0.76em" }}>
            <Col>
              <p
                style={{
                  textAlign: "center",
                  color: "#F14E95",
                  fontWeight: "600",
                }}
              >
                Después de completar esta información podrás ir a la seccion de
                múltimedia para agregar imágenes y videos que el plan que
                elegiste te permita.
              </p>
            </Col>
          </Row>
          <Row>
            <div className={`progressSteps`}>
              <Button
                onClick={() => {
                  setKey("3");
                }}
              >
                Anterior
              </Button>

              <Button
                disabled={!uploaded}
                onClick={() => toAccount()}
                className={`progressButtons ${key === "4" ? "" : "notshow"}`}
              >
                Finalizar
              </Button>
            </div>
          </Row>
        </Tab>
      </Tabs>
    </Container>
  );
};

export default AccountInformationPage;
