import { Route, Routes } from "react-router-dom"
import { HomePage } from "../modules/home/homePage"
import { ProvidersPage } from "../modules/providers/providersPage"
import { ProviderPage } from "../modules/provider/providerPage"
import { RegisterProviderPage } from "../modules/register/registerProvider"
import { EventsPage } from "../modules/events/eventsPage"
import { AboutUs } from "../modules/general/aboutUs"
import { PrivacyNotice } from "../modules/general/privacyNotice"
import { ImprovePage } from "../modules/general/improvePage"
import { SearchPage } from "../modules/general/searchPage"
import { WhislistPage } from "../modules/wishlist/wishlistPage"
import { PromotionsPage } from "../modules/promotions/promotionsPage"
import { PromotionPage } from "../modules/promotion/promotionPage"
import { TermsConditions } from "../modules/general/termsConditions"
import { LoginPage } from "../modules/login/login"
import AccountPage from "../modules/account/account"
import AccountInformationPage from "../modules/account/accountInformation"
import AccountDetailModel from "../models/Account/AccountDetailModel"
import RecoveryPasswordPage from "../modules/login/recoveryPassword"
import ContactoPage from "../modules/contacto/contactoPage"

export const Content = () => {

    return(
        <Routes>           
            <Route path="/" element={<HomePage />} />
            <Route path="/home" element={<HomePage />} />
            <Route path="/providers" element={<ProvidersPage />} />
            <Route path="/provider/:id" element={<ProviderPage />} />
            <Route path="/events/:idEvent" element={<EventsPage />} />
            <Route path="/register_provider" element={<RegisterProviderPage />} />
            <Route path="/aboutus" element={<AboutUs />} />
            <Route path="/privacy_notice" element={<PrivacyNotice />} />
            <Route path="/improve" element={<ImprovePage />} />
            <Route path="/search" element={<SearchPage />} />
            <Route path="/wishlist" element={<WhislistPage />} />
            <Route path="/promotions" element={<PromotionsPage />} />
            <Route path="/terms_conditions" element={<TermsConditions />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/promotion/:id" element={<PromotionPage />} />
            <Route path="/account" element={<AccountPage />} />
            <Route path="/account_information" element={<AccountInformationPage />} />
            <Route path="/recovery_password" element={<RecoveryPasswordPage />} />
            <Route path="/contacto" element={<ContactoPage />} />

        </Routes>
    )
}