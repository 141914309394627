

const getNextMonthDateMexicoFormat = (): string => {
    const today = new Date();
    const nextMonthDate = new Date(today);

    // Establece el mes siguiente
    nextMonthDate.setMonth(today.getMonth() + 1);

    // Formato de fecha para México
    return nextMonthDate.toLocaleDateString("es-MX", {
      day: "numeric",
      month: "long",
      year: "numeric",
    });
  };

  export default getNextMonthDateMexicoFormat;