

export default class AccountDetailModel{
    public id: number = 0;
    public username: string = "";
    public name: string = "";
    public contact_name: string = "";
    public description: string = "";
    public phone: string = "" ;
    public website: string = "";
    public instagram: string = "";
    public facebook: string = "";
    public tiktok: string = "";
    public youtube: string = "";
    public street: string = "";
    public street_number: string = "";
    public suite_number: string = "";
    public cp: string = "";
    public entity: string = "";
    public state: string = "";
    public plan: 'F' | 'CB' | 'CM' | 'CS' | 'CO' | 'CP' = 'F';
    public selectedPlan: 'F' | 'CB' | 'CM' | 'CS' | 'CO' | 'CP' = 'F';
    public links_media: any = [];
    public path: string = '';
    public rfc: string = "";
    public curp: string = "";
    public second_contact_name: string = "";
    public second_contact_email: string = "";
    public third_contact_name: string = "";
    public third_contact_email: string = "";
    public min_amount: number = 0.00;
    public max_amount: number = 0.00;
    public categories: number[] = [];
    public services: number[] = [];
    public acceptTerms: boolean =  false;
    public other_services: string = "";
    public acceptMediaTerms: number = 0;
    public price_range: string = '';
    
}